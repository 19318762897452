import { takeLatest, all, put, call } from "redux-saga/effects";

import { toast } from "react-toastify";

import { loginUser, verifyLoggedUser, logoutUser } from "./async_calls";

function* loginASync(action) {
  try {
    yield put({ type: "SET_LOADING", payload: true });
    const resp = yield call(loginUser, action.payload);

    if (resp?.status) {
      yield put({ type: "LOGIN_ASYNC", payload: resp.data });
    } else {
      yield put({ type: "SET_LOADING", payload: false });
      return toast.error("Invalid Credentials");
    }
  } catch (err) {
    console.log("error", err);
    yield put({ type: "SET_LOADING", payload: false });
  }
}

function* verifyLoginASync() {
  try {
    const resp = yield call(verifyLoggedUser);
    if (resp?.status) {
      yield put({ type: "LOGIN_ASYNC", payload: { ...resp.data, isAuth: true } });
    }
  } catch (err) {
    console.log("error", err);
  }
}

function* logoutASync() {
  try {
    const resp = yield call(logoutUser);
    if (resp?.status) {
      yield put({ type: "LOGOUT", payload: resp });
    }
  } catch (err) {
    console.log(err);
  }
}

export default function* watchAll() {
  yield all([
    yield takeLatest("LOGIN", loginASync),
    verifyLoginASync(),
    yield takeLatest("LOGOUT", logoutASync),
  ]);
}
