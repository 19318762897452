import { combineReducers } from "redux";
import titleReducer from "./title/reduces";
import loginReducer from "./user/reducer";

const allReducers = combineReducers({
  user: loginReducer,
  header: titleReducer,
});

export default allReducers;
