// @mui material components
//import Container from "@mui/material/Container";
//import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
//import MKTypography from "components/MKTypography";
//import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "./images/inner_header.jpg";
import mdImg from "./images/team/md.jpg";
import dummy from "./images/team/photo_dummy.jpg";
import MKTypography from "components/MKTypography";

import "./style.css";

function Team() {
  return (
    <>
      <DefaultNavbar routes={routes} relative center />

      <MKTypography
        variant="h2"
        sx={{
          textAlign: "center",
          backgroundImage: `url(${bgImage})`,
          color: "rgb(255,255,255)",
          padding: "150px 0",
          height: "350px",
          fontFamily: "Helvetica",
          maxWidth: "100%",
        }}
      >
        BOARD OF DIRECTORS
      </MKTypography>

      <Card
        sx={{
          //mx: { xs: 2, lg: 3 },
          mt: 0,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          paddingLeft: "15%",
          paddingRight: "15%",
          width: "100vw",
          minWidth: "100vw",
          textAlign: "center",
        }}
      >
        <div className="teamMain">
          <div
            style={{
              border: "2px solid rgba(104, 35, 106)",
              maxWidth: "250px",
              borderRadius: "10px",
              marginTop: "20px",
              padding: "5PX",
            }}
          >
            <img src={mdImg} alt="md" style={{ padding: "20px", maxWidth: "200px" }} />
            <MKTypography variant="h5" sx={{ textAlign: "center" }}>
              JAGAJITH PRABAKARAN
            </MKTypography>
            <MKTypography variant="h6" sx={{ textAlign: "center" }}>
              FOUNDER, CHAIRMAN & MD
            </MKTypography>
          </div>
          <div
            style={{
              border: "2px solid rgba(104, 35, 106)",
              maxWidth: "250px",
              borderRadius: "10px",
              marginTop: "20px",
            }}
          >
            <img src={dummy} alt="director" style={{ padding: "20px", maxWidth: "200px" }} />
            <MKTypography variant="h5" sx={{ textAlign: "center" }}>
              SUSHI KV
            </MKTypography>
            <MKTypography variant="h6" sx={{ textAlign: "center" }}>
              DIRECTOR
            </MKTypography>
          </div>
          <div
            style={{
              border: "2px solid rgba(104, 35, 106)",
              maxWidth: "250px",
              borderRadius: "10px",
              marginTop: "20px",
            }}
          >
            <img src={dummy} alt="director" style={{ padding: "20px", maxWidth: "200px" }} />
            <MKTypography variant="h5" sx={{ textAlign: "center" }}>
              SUMANA JAGAJITH
            </MKTypography>
            <MKTypography variant="h6" sx={{ textAlign: "center" }}>
              DIRECTOR
            </MKTypography>
          </div>
          <div
            style={{
              border: "2px solid rgba(104, 35, 106)",
              maxWidth: "250px",
              borderRadius: "10px",
              marginTop: "20px",
            }}
          >
            <img src={dummy} alt="director" style={{ padding: "20px", maxWidth: "200px" }} />
            <MKTypography variant="h5" sx={{ textAlign: "center" }}>
              MAHENDRAJITH
            </MKTypography>
            <MKTypography variant="h6" sx={{ textAlign: "center" }}>
              DIRECTOR
            </MKTypography>
          </div>
        </div>
      </Card>
      <MKBox>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Team;
