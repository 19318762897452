import { LOGIN_ASYNC, LOGOUT, SET_LOADING } from "./action";

const initialState = {
  loading: false,
  id: "",
  fullname: "",
  email: "",
  role: "",
  isAuth: false,
};

function loginReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOGIN_ASYNC:
      state.isAuth = true;
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case LOGOUT:
      state.isAuth = false;
      state.role = "";
      return {
        ...state,
        initialState,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
}

export default loginReducer;
