// @mui material components
//import Container from "@mui/material/Container";
//import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
//import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
//import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";

// About Us page sections

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import whatsapp from "assets/images/whatsapp.png";
import mail from "assets/images/mail.png";
import landmark from "assets/images/landmark.png";
import Form from "./form";
import bgImg from "assets/images/inner_header.jpg";
// Images

function Contact() {
  return (
    <>
      <DefaultNavbar routes={routes} relative />
      <MKTypography
        variant="h2"
        sx={{
          textAlign: "center",
          color: "rgb(255,255,255)",
          padding: "150px 0",
          height: "350px",
          fontFamily: "Helvetica",
          backgroundImage: `url(${bgImg})`,
        }}
      >
        CONTACT US
      </MKTypography>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: 0,
          mb: 1,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <div style={{ display: "flex", width: "100vw", marginTop: "25px" }}>
          <div
            style={{
              maxWidth: "50%",
              width: "40%",
              textAlign: "center",
              borderRight: "2px solid rgba(104,35,106)",
            }}
          >
            <div>
              <img src={whatsapp} alt="whatsapp" style={{ width: "50px", height: "50px" }} />
              <MKTypography sx={{ fontSize: "16px" }}>+968 9491 6916</MKTypography>
            </div>
            <div style={{ marginTop: "20px" }}>
              <img src={mail} alt="mail" style={{ width: "50px", height: "50px" }} />
              <MKTypography sx={{ fontSize: "16px" }}>info@cochingoldanddiamonds.com</MKTypography>
            </div>
            <div style={{ marginTop: "20px" }}>
              <img src={landmark} alt="landmark" style={{ width: "50px", height: "50px" }} />
              <MKTypography sx={{ fontSize: "16px" }}>Head Office</MKTypography>
              <MKTypography sx={{ fontSize: "16px" }}>Ruwi Highstreet, Muscat</MKTypography>
              <MKTypography sx={{ fontSize: "16px" }}>Sultanate of Oman</MKTypography>
            </div>
          </div>
          <div
            style={{
              maxWidth: "50%",
              width: "50%",
              textAlign: "center",
              margin: "auto",
            }}
          >
            <Form />
          </div>
        </div>
      </Card>
      <MKBox pt={6} mt={1}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Contact;
