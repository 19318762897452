// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/manf_logo.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Cochin Gold & Diamonds",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com",
    },
    {
      icon: <GitHubIcon />,
      link: "https://github.com/",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/",
    },
  ],
  menus: [
    {
      name: "USEFUL LINKS",
      items: [
        { name: "The Story", href: "/#/aboutus" },
        { name: "Management Team", href: "/#/team" },
        { name: "Services" },
        { name: "Schemes" },
        { name: "FAQ" },
      ],
    },
    {
      name: "POLICIES",
      items: [
        { name: "Payback Policy" },
        { name: "Exchange Policy" },
        { name: "Cancellation Policy" },
        { name: "Privacy Policy" },
        { name: "Terms of Policy" },
      ],
    },
    {
      name: "CUSTOMER SERVICES",
      items: [
        { name: "Payment Methods" },
        { name: "Money-Back Guarantee" },
        { name: "Shipping Policy" },
        { name: "Terms and Condition" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular" sx={{ color: "rgb(255, 255, 255)" }}>
      All rights reserved. Copyright &copy; {date} by COCHIN GOLD & DIAMONDS
    </MKTypography>
  ),
};
