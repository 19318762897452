// @mui material components
//import Container from "@mui/material/Container";
//import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
//import MKTypography from "components/MKTypography";
//import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Images
import img1 from "./images/1.jpg";
import img2 from "./images/2.jpg";
import img3 from "./images/3.jpg";
//import img4 from "./images/slider 4.jpg";
import jew1 from "./images/jewellery/img1.png";
import jew2 from "./images/jewellery/img2.jpg";
import jew3 from "./images/jewellery/img3.jpeg";
import jew4 from "./images/jewellery/img4.jpg";
import jew5 from "./images/jewellery/img5.jpg";
import jew6 from "assets/images/banner.jpg";

import Slider from "react-slick";
import "./style.css";

//import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";

const images = [img1, img2, img3];
import MKTypography from "components/MKTypography";
import { Grid } from "@mui/material";

function Home() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: false,
    speed: 1000,
  };

  return (
    <>
      <DefaultNavbar routes={routes} relative />
      <Slider {...settings}>
        {images.map((i, index) => (
          <div key={index}>
            <img
              src={i}
              alt={`${i}`}
              style={{
                position: "static",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: "100vw",
                maxWidth: "100vw",
                minWidth: "100vw",
              }}
            />
          </div>
        ))}
      </Slider>
      <Card
        sx={{
          margin: "auto",
          mt: "40px",
          mb: 4,
          width: "100%",
          paddingLeft: "15%",
          paddingRight: "15%",
          overflow: "hidden",
        }}
      >
        <MKTypography
          width="70%"
          sx={{
            textAlign: "center",
            margin: "auto",
            fontSize: 16,
            fontWeight: 400,
            textJustify: "inter-word",
            color: "rgb(69, 69, 69)",
          }}
        >
          The story of Cochin Gold & Diamonds begins three decades ago. Cochin Gold & Diamonds is a
          well-established jewellery firm that has grown from a small initiative to establish a
          retail gold and diamond jewellery brand in Oman...
        </MKTypography>
        <MKTypography
          variant="h5"
          sx={{
            textAlign: "center",
            mt: "40px",
            color: "rgba(104, 35, 106)",
          }}
        >
          FEATURED PRODUCTS
        </MKTypography>
        <div style={{ width: "100%" }}>
          <div
            style={{
              width: "10%",
              backgroundColor: "rgb(104, 35, 106)",
              height: "2px",
              margin: "auto",
            }}
          />
        </div>
        <div
          style={{
            width: "100%",
            margin: "auto",
            display: "flex",
            marginTop: 40,
            overflowX: "auto",
            justifyContent: "space-between",
          }}
        >
          <Grid
            width="250px"
            height={"250px"}
            sx={{ margin: 1, textAlign: "center", position: "relative" }}
          >
            <div style={{ display: "flex" }}>
              <img
                src={jew1}
                alt="jew1"
                width="250px"
                height={"250px"}
                style={{ padding: 5, boxShadow: "1px 1px 5px -5px" }}
              />
              <div style={{ position: "absolute", width: "20%", margin: 5 }}>
                <MKTypography
                  sx={{
                    backgroundColor: "rgba(106, 2, 115, 0.7)",
                    color: "rgb(255, 255, 255)",
                    fontSize: "0.7rem",
                  }}
                >
                  New
                </MKTypography>
              </div>
            </div>
            {/* <MKTypography sx={{ mt: 0, float: "left", fontSize: 16 }}>OMR: 900</MKTypography> */}
          </Grid>
          <Grid width="250px" sx={{ margin: 1, textAlign: "center", position: "relative" }}>
            <div style={{ display: "flex" }}>
              <img
                src={jew2}
                alt="jew1"
                width="250px"
                height={"250px"}
                style={{ padding: 5, boxShadow: "1px 1px 5px -5px" }}
              />
              <div style={{ position: "absolute", width: "20%", margin: 5 }}>
                <MKTypography
                  sx={{
                    backgroundColor: "rgba(196, 2, 115, 0.7)",
                    color: "rgb(255, 255, 255)",
                    fontSize: "0.7rem",
                  }}
                >
                  Sale
                </MKTypography>
              </div>
            </div>
            {/* <MKTypography sx={{ mt: 0, float: "left", fontSize: 16 }}>OMR: 1100</MKTypography> */}
          </Grid>
          <Grid width="250px" sx={{ margin: 1 }}>
            <div style={{ display: "flex" }}>
              <img
                src={jew3}
                alt="jew1"
                width="250px"
                height={"250px"}
                style={{ padding: 5, boxShadow: "1px 1px 5px -5px" }}
              />
            </div>
            {/* <MKTypography sx={{ mt: 0, float: "left", fontSize: 16 }}>OMR: 850</MKTypography> */}
          </Grid>
          <Grid width="250px" sx={{ margin: 1 }}>
            <div style={{ display: "flex" }}>
              <img
                src={jew4}
                alt="jew1"
                width="250px"
                height={"250px"}
                style={{ padding: 5, boxShadow: "1px 1px 5px -5px" }}
              />
            </div>
            {/* <MKTypography sx={{ mt: 0, float: "left", fontSize: 16 }}>OMR: 700</MKTypography> */}
          </Grid>
        </div>
        <div style={{ minWidth: "100%", marginTop: "40px" }}>
          <img
            src={jew6}
            alt="ad banner2"
            style={{
              position: "static",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              minWidth: "100%",
              maxWidth: "100%",
              placeItems: "center",
            }}
          />
        </div>
        <div style={{ display: "flex", marginTop: "40px", width: "100%" }}>
          <div className="sideImg">
            <img src={jew5} alt="img" style={{ marginLeft: 10, maxWidth: "100%" }} />
          </div>
          <div className="sidedetails">
            <MKTypography
              sx={{ textAlign: "justify", fontWeight: 400, fontSize: 16, color: "rgb(69, 69, 69)" }}
            >
              <span style={{ fontWeight: 500, color: "rgba(104, 35, 106)" }}>
                COCHIN GOLD & DIAMONDS
              </span>{" "}
              believes in providing the customer with superior quality jewellery within their budget
              and gives matchless expert assistance both during and after the purchase. We offer a
              huge range of jewellery from variety of brands as well as our own handcrafted designs
            </MKTypography>
            <MKTypography
              sx={{
                mt: 5,
                textAlign: "justify",
                fontWeight: 400,
                fontSize: 16,
                color: "rgb(69, 69, 69)",
              }}
            >
              Our passionate and committed team created a successful environment that thrives on
              trust, respect, diversity, motivation and team spirit. We provide professional
              training to build up their skills with hands-on experience. Besides, we have an
              after-sales follow-up team for taking feedback from customers which has been
              invaluable in improving our performance
            </MKTypography>
            <MKTypography
              sx={{
                mt: 5,
                textAlign: "justify",
                fontWeight: 400,
                fontSize: 16,
                color: "rgb(69, 69, 69)",
              }}
            >
              {" "}
              <span style={{ fontWeight: 500, color: "rgba(104, 35, 106)" }}>
                Cochin Gold & Diamonds
              </span>{" "}
              is committed to make every customer feel satisfied with our personalized service and
              our exquisite designs. We have built a legacy of reliability and trust with our
              exclusive services. We earn our customer’s loyalty through trust and integrity and we
              create memorable shopping experiences to our valuable customers
            </MKTypography>
            <MKTypography
              sx={{
                mt: 5,
                textAlign: "justify",
                fontWeight: 400,
                fontSize: 16,
                color: "rgb(69, 69, 69)",
              }}
            >
              We create our own jewellery at our parent firm Assarain Jewellery LLC, which ensures
              that our valuable customers get them at a fair price. We offer unique designs and
              create custom work, and ensure that you get the product you desire and considering the
              value for your money. We are always passionate in offering exceptional quality and
              value addition to every piece crafted.
            </MKTypography>
          </div>
        </div>
      </Card>
      <MKBox>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Home;
