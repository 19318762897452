// @mui material components
//import Container from "@mui/material/Container";
//import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
//import MKTypography from "components/MKTypography";
//import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "./images/inner_header.jpg";
import logo from "assets/images/logo.png";
import MKTypography from "components/MKTypography";

function AboutUs() {
  return (
    <>
      <DefaultNavbar routes={routes} relative center />

      <MKTypography
        variant="h2"
        sx={{
          textAlign: "center",
          backgroundImage: `url(${bgImage})`,
          color: "rgb(255,255,255)",
          padding: "150px 0",
          height: "350px",
          fontFamily: "Helvetica",
        }}
      >
        COCHIN GOLD & DIAMONDS
      </MKTypography>

      <Card
        sx={{
          p: 2,
          //mx: { xs: 2, lg: 3 },
          mt: 0,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          paddingLeft: "15%",
          paddingRight: "15%",
          maxWidth: "100vw",
        }}
      >
        <div style={{ width: "100%", marginTop: "40px" }}>
          <div style={{ float: "left" }}>
            <img
              src={logo}
              alt="logo"
              //width={"200px"}
              className="logopara" /*  style={{ marginRight: "30px" }} */
            />
          </div>

          <div>
            <MKTypography
              sx={{
                fontSize: "16px",
                textAlign: "justify",
                textJustify: "inter-word",
                color: "rgb(69, 69, 69)",
              }}
            >
              The story of Cochin Gold & Diamonds begins three decades ago. Cochin Gold & Diamonds
              is a well-established jewellery firm that has grown from a small initiative to
              establish a retail gold and diamond jewellery brand in Oman to three well- placed
              outlets across the country.
            </MKTypography>
            <MKTypography
              sx={{
                fontSize: "16px",
                textAlign: "justify",
                textJustify: "inter-word",
                color: "rgb(69, 69, 69)",
                mt: "30px",
              }}
            >
              In 1987, its dynamic Founder & Managing Director Mr. Jagajith Prabhakaran Manakunnath,
              an expert in gold and diamond jewellery manufacturing, wholesale and retail, explored
              the possibility of establishing a retail gold and diamond jewellery brand in Oman. The
              parent company Assarain Jewellery LLC, which commenced manufacturing and wholesale
              operations in 1993, has been delivering high-quality services for more than two
              decades.
            </MKTypography>
            <MKTypography
              sx={{
                fontSize: "16px",
                textAlign: "justify",
                textJustify: "inter-word",
                color: "rgb(69, 69, 69)",
                mt: "30px",
              }}
            >
              Assarain Gold LLC opened its first gold and diamond jewellery showroom under the brand
              name ‘COCHIN GOLD & DIAMONDS’ at Ruwi High Street on April 16, 2015. Presently, with
              three showrooms at convenient locations, Cochin Gold & Diamonds offers the customer
              the best product at low-making charges. Here, purity and quality are traditional
              bywords.
            </MKTypography>
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "rgb(104, 35, 106)",
              height: "2px",
              margin: "auto",
              marginTop: "30px",
            }}
          />
        </div>
        <div>
          <MKTypography
            variant="h5"
            sx={{
              textAlign: "center",
              mt: "40px",
              color: "rgba(104, 35, 106)",
            }}
          >
            BUSINESS STRATEGIES
          </MKTypography>{" "}
          <div style={{ width: "100%" }}>
            <div
              style={{
                width: "20%",
                backgroundColor: "rgb(104, 35, 106)",
                height: "3px",
                margin: "auto",
              }}
            />
          </div>
          <div>
            <MKTypography
              variant="h5"
              sx={{
                mt: "40px",
                color: "rgba(104, 35, 106)",
                fontwight: 400,
              }}
            >
              Craftsmanship
            </MKTypography>
            <MKTypography sx={{ mt: "16px", color: "rgb(69, 69, 69)", fontSize: "16px" }}>
              With over 30 years’ experience in the jewellery industry, Cochin Gold & Diamonds
              earned valuable credibility in the market. Authentic craftsmanship was passed on
              throughout time, guaranteeing that every piece from Cochin Gold & Diamonds gets
              created with a remarkable design that takes your breath away.
            </MKTypography>
          </div>
          <div>
            <MKTypography
              variant="h5"
              sx={{
                mt: "40px",
                color: "rgba(104, 35, 106)",
                fontwight: 400,
              }}
            >
              Innovations
            </MKTypography>
            <MKTypography sx={{ mt: "16px", color: "rgb(69, 69, 69)", fontSize: "16px" }}>
              Because we believe quality comes from innovation, creativity is at the heart of our
              brand. First, we hand-pick the finest jewels and then craft them into unique designs
              using state-of-the-art techniques. This era’s constant demand for innovation is what
              allows us to realize our true potential.
            </MKTypography>
          </div>
          <div>
            <MKTypography
              variant="h5"
              sx={{
                mt: "40px",
                color: "rgba(104, 35, 106)",
                fontwight: 400,
              }}
            >
              Trust & Quality
            </MKTypography>
            <MKTypography sx={{ mt: "16px", color: "rgb(69, 69, 69)", fontSize: "16px" }}>
              Over the years, Cochin Gold & Diamonds earned its customers trust by consistently
              providing excellent quality, unparalleled designs and unmatched craftsmanship. Till
              this Day, Cochin Gold & Diamonds is founded on the toughest of standards. Our experts
              conduct thorough quality checks every day in efforts to impress customers with the
              most flawless and beautiful jewellery in the market.
            </MKTypography>
          </div>
          <div>
            <MKTypography
              variant="h5"
              sx={{
                mt: "40px",
                color: "rgba(104, 35, 106)",
                fontwight: 400,
              }}
            >
              Customers
            </MKTypography>
            <MKTypography sx={{ mt: "16px", color: "rgb(69, 69, 69)", fontSize: "16px" }}>
              Our customers have been the foundation of our success. Our customers are our source of
              inspiration:
              <br />
              – Earn our customers’ loyalty through integrity and trust <br />
              – Create memorable shopping experiences for our customers <br />
              – Exceed customer expectations through the standard of our service and unmatched
              quality jewellery <br />
              – Be the brand of choice
              <br />
            </MKTypography>
          </div>
          <div className="strategies">
            <div>
              <MKTypography
                variant="h5"
                sx={{
                  color: "rgba(104, 35, 106)",
                  fontwight: 400,
                }}
              >
                Our Mission
              </MKTypography>
              <MKTypography sx={{ mt: "16px", color: "rgb(69, 69, 69)", fontSize: "16px" }}>
                To ensure the happiness of our customer by creating priceless moments using an art
                of fine jewellery.
              </MKTypography>
            </div>
            <div className="ourVision">
              <MKTypography
                variant="h5"
                sx={{
                  color: "rgba(104, 35, 106)",
                  fontwight: 400,
                }}
              >
                Our Vision
              </MKTypography>
              <MKTypography sx={{ mt: "16px", color: "rgb(69, 69, 69)", fontSize: "16px" }}>
                To be the No. 1 branded retailer & manufacturer that offers exclusive jewellery at
                an affordable price to maintain our tradition of trust and quality.
              </MKTypography>
            </div>
          </div>
        </div>
      </Card>
      <MKBox pt={6} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default AboutUs;
