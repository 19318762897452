/* eslint-disable react/prop-types */
// @mui material components
//import Container from "@mui/material/Container";
//import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
//import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
//import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";

// About Us page sections

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import whatsapp from "assets/images/whatsapp.png";
import landmark from "assets/images/landmark.png";
import bgImg from "assets/images/inner_header.jpg";
import GoogleMapReact from "google-map-react";
import { Button } from "@mui/material";
import "./style.css";

import store1 from "assets/images/store/store1.png";
import { useState } from "react";
const K_WIDTH = 40;
const K_HEIGHT = 40;

const greatPlaceStyle = {
  // initially any map object has left top corner at lat lng coordinates
  // it's on you to set object origin to 0,0 coordinates
  position: "absolute",
  width: K_WIDTH,
  height: K_HEIGHT,
  left: -K_WIDTH / 2,
  top: -K_HEIGHT / 2,

  border: "5px solid #f44336",
  borderRadius: K_HEIGHT,
  backgroundColor: "white",
  textAlign: "center",
  color: "#3f51b5",
  fontSize: 16,
  fontWeight: "bold",
  padding: 4,
};
const AnyReactComponent = ({ text }) => <div style={greatPlaceStyle}>{text}</div>;
// Images

function Stores() {
  const [defaultProps, setDefaultProps] = useState({
    center: {
      lat: 23.826733564993372,
      lng: 57.19652579468328,
    },
    zoom: 8,
  });

  const handleLocation = (lat = 23.826733564993372, lan = 57.19652579468328, zoom = 8) => {
    const data = {
      center: {
        lat: lat,
        lng: lan,
      },
      zoom: zoom,
    };
    setDefaultProps(data);
  };
  console.log("defaultProps", defaultProps);

  return (
    <>
      <DefaultNavbar routes={routes} relative />
      <MKTypography
        variant="h2"
        sx={{
          textAlign: "center",
          color: "rgb(255,255,255)",
          padding: "150px 0",
          height: "350px",
          fontFamily: "Helvetica",
          backgroundImage: `url(${bgImg})`,
        }}
      >
        OUR OUTLETS
      </MKTypography>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: 0,
          mb: 1,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <div style={{ display: "flex", width: "100vw", marginTop: "25px" }}>
          <div className="storeBody">
            {/* store 1 */}
            <div style={{ display: "flex" }}>
              <div className="storeImg">
                <img src={store1} alt="store1" style={{ height: "150px", width: "200px" }} />
              </div>
              <div style={{ textAlign: "center", margin: "auto" }}>
                <MKTypography variant="h5">Ruwi Highstreet</MKTypography>
                <MKTypography sx={{ fontSize: "16px" }}> Muscat, Oman</MKTypography>
                <div style={{ marginTop: "10px" }}>
                  <a
                    style={{ color: "#FFF" }}
                    href="https://wa.me/+96894940916"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button variant="outlined" color="secondary">
                      <img
                        src={whatsapp}
                        alt="whatsapp"
                        style={{ height: "16px", width: "16px" }}
                      />{" "}
                      <p style={{ color: "#000000" }}>+968 9494 0916</p>
                    </Button>
                  </a>
                  {/*  <a
                    style={{ color: "#FFF" }}
                    href="https://maps.app.goo.gl/WMnRo8eFXyaZws2y9"
                    target="_blank"
                    rel="noopener noreferrer"
                  > */}
                  <Button
                    variant="outlined"
                    color="secondary"
                    style={{ marginLeft: "10px" }}
                    onClick={() => handleLocation(23.592309584756574, 58.54464960278408, 15)}
                  >
                    <img src={landmark} alt="whatsapp" style={{ height: "16px", width: "16px" }} />
                    <p style={{ color: "#000000" }}>View in Maps</p>
                  </Button>
                  {/*  </a> */}
                </div>
              </div>
            </div>
            {/* store 2 */}
            <div style={{ display: "flex", marginTop: "20px" }}>
              <div className="storeImg">
                <img src={store1} alt="store1" style={{ height: "150px", width: "200px" }} />
              </div>
              <div style={{ textAlign: "center", margin: "auto" }}>
                <MKTypography variant="h5" sx={{ fontSize: "16px" }}>
                  Oman Avenues Mall
                </MKTypography>
                <MKTypography sx={{ fontSize: "16px" }}>Gold Souk, Ground Floor</MKTypography>
                <MKTypography sx={{ fontSize: "16px" }}> Oman Avenue Mall, Al Ghubrah</MKTypography>
                <div style={{ marginTop: "10px" }}>
                  <a
                    style={{ color: "#FFF" }}
                    href="https://wa.me/+96897992294"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button variant="outlined" color="secondary">
                      <img
                        src={whatsapp}
                        alt="whatsapp"
                        style={{ height: "16px", width: "16px" }}
                      />{" "}
                      <p style={{ color: "#000000" }}>+968 9799 2294</p>
                    </Button>
                  </a>
                  {/* <a
                    style={{ color: "#FFF" }}
                    href="https://maps.app.goo.gl/uyyREQWZprEoythg7"
                    target="_blank"
                    rel="noopener noreferrer"
                  > */}
                  <Button
                    variant="outlined"
                    color="secondary"
                    style={{ marginLeft: "10px" }}
                    onClick={() => handleLocation(23.591661632122815, 58.4108388080692, 15)}
                  >
                    <img src={landmark} alt="whatsapp" style={{ height: "16px", width: "16px" }} />
                    <p style={{ color: "#000000" }}>View in Maps</p>
                  </Button>
                  {/* </a> */}
                </div>
              </div>
            </div>
            {/* store 3 */}
            <div style={{ display: "flex", marginTop: "20px" }}>
              <div className="storeImg">
                <img src={store1} alt="store1" style={{ height: "150px", width: "200px" }} />
              </div>
              <div style={{ textAlign: "center", margin: "auto" }}>
                <MKTypography variant="h5" sx={{ fontSize: "16px" }}>
                  SOHAR SOUQ
                </MKTypography>
                <MKTypography sx={{ fontSize: "16px" }}>
                  {" "}
                  Opp. Sohar Developent Office{" "}
                </MKTypography>
                <MKTypography sx={{ fontSize: "16px" }}> Al Hambar Street, Sohar </MKTypography>
                <div style={{ marginTop: "10px" }}>
                  <a
                    style={{ color: "#FFF" }}
                    href="https://wa.me/+96894861488"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button variant="outlined" color="secondary">
                      <img
                        src={whatsapp}
                        alt="whatsapp"
                        style={{ height: "16px", width: "16px" }}
                      />{" "}
                      <p style={{ color: "#000000" }}>+968 9486 1488</p>
                    </Button>
                  </a>
                  {/* <a
                    style={{ color: "#FFF" }}
                    href="https://maps.app.goo.gl/g3HN1BwT5GygzDAr7"
                    target="_blank"
                    rel="noopener noreferrer"
                  > */}
                  <Button
                    variant="outlined"
                    color="secondary"
                    style={{ marginLeft: "10px" }}
                    onClick={() => handleLocation(24.350325654371343, 56.7341566268012, 15)}
                  >
                    <img src={landmark} alt="whatsapp" style={{ height: "16px", width: "16px" }} />
                    <p style={{ color: "#000000" }}>View in Maps</p>
                  </Button>
                  {/* </a> */}
                </div>
              </div>
            </div>
          </div>
          <div
            className="map"
            style={{
              maxWidth: "50%",
              width: "50%",
              height: "70vh",
              marginLeft: "10px",
            }}
          >
            <GoogleMapReact
              //bootstrapURLKeys={{ key: "" }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
              center={defaultProps.center}
              zoom={defaultProps.zoom}
            >
              <AnyReactComponent lat={23.592309584756574} lng={58.54464960278408} text={"1"} />
              <AnyReactComponent lat={23.591661632122815} lng={58.4108388080692} text={"2"} />
              <AnyReactComponent lat={24.350325654371343} lng={56.73415662680125} text={"3"} />
            </GoogleMapReact>
          </div>
        </div>
      </Card>
      <MKBox pt={6} mt={1}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Stores;
