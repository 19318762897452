import React from "react";
import MKInput from "components/MKInput";
import Button from "@mui/material/Button";
import { purple } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import MKTypography from "components/MKTypography";
import { TextField } from "@mui/material";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[700]),
  backgroundColor: purple[500],
  "&:hover": {
    backgroundColor: purple[600],
  },
}));

export default function Form() {
  return (
    <div
      style={{
        width: "90%",
        display: "flex",
        flexDirection: "column",
        /* border: "2px solid rgba(104,35,106)",
        borderRadius: "5px", */
        margin: "auto",
        paddingTop: "30px",
        paddingBottom: "30px",
      }}
    >
      <MKTypography variant="h4" sx={{ color: "rgba(104,35,106)" }}>
        ENQUIRY
      </MKTypography>
      <div style={{ marginTop: "20px", marginLeft: "5%", maxWidth: "90%" }}>
        <MKInput label="First Name*" InputLabelProps={{ shrink: true }} sx={{ width: "49%" }} />
        <MKInput
          label="Last Name"
          InputLabelProps={{ shrink: true }}
          sx={{ marginLeft: "1%", width: "49%" }}
        />
      </div>
      <div style={{ maxWidth: "90%", marginTop: "20px", marginLeft: "5%" }}>
        <MKInput label="Email*" type="email" InputLabelProps={{ shrink: true }} fullWidth />
      </div>
      <div style={{ maxWidth: "90%", marginTop: "20px", marginLeft: "5%" }}>
        <MKInput
          label="Phone"
          placeholder="Optional"
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      </div>
      <div style={{ maxWidth: "90%", marginTop: "20px", marginLeft: "5%" }}>
        <TextField
          id="outlined-multiline-static"
          label="How can we help you?"
          multiline
          rows={4}
          fullWidth
        />
      </div>
      <ColorButton
        size="large"
        type="submit"
        variant="contained"
        sx={{ marginTop: "10px", width: "40%", marginLeft: "30%" }}
      >
        Enquire
      </ColorButton>
    </div>
  );
}
