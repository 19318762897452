/* eslint-disable react/prop-types */
import * as Yup from "yup";
import { useState } from "react";
import { /* Link as RouterLink , */ useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
// material
import { Stack, TextField, IconButton, InputAdornment } from "@mui/material";
import { connect } from "react-redux";
//import "../../../redux/user/user.css";
// component
import Iconify from "components/Iconify";

// ----------------------------------------------------------------------

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { purple } from "@mui/material/colors";
import Client from "services/api";
import { toast } from "react-toastify";
import axios from "axios";

axios.defaults.withCredentials = true;

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  "&:hover": {
    backgroundColor: purple[700],
  },
}));

const LoginForm = (props) => {
  const navigate = useNavigate();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Email must be a valid email address").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      phone: null,
      password: null,
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setSubmitting }) => {
      console.log("setSubmitting", setSubmitting);
      //
      const userdetails = {
        email: document.getElementById("email").value,
        name: document.getElementById("username").value,
        phone: document.getElementById("phone").value,
        password: document.getElementById("upwd").value,
      };
      console.log("userdetails", userdetails);
      const resp = await Client.post("/adduser");
      if (resp.data.status) {
        toast.success("Account Created! Check email for details");
      }
      setTimeout(() => {
        setSubmitting(false);
        navigate(`/verify?user=${userdetails.email}`, { replace: true });
      }, 2000);
      // if(props.isAuth) navigate('/', { replace: true });
    },
  });

  const { errors, touched, /* values, isSubmitting, */ handleSubmit, getFieldProps } = formik;
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            size="large"
            fullWidth
            //autoComplete="username"
            type="email"
            label="FullName"
            id="username"
            {...getFieldProps("username")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField
            size="large"
            fullWidth
            //autoComplete="email"
            type="email"
            label="Email address"
            id="email"
            {...getFieldProps("email")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField
            size="large"
            fullWidth
            //autoComplete="phone"
            type="email"
            label="Phone No."
            id="phone"
            {...getFieldProps("phone")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField
            fullWidth
            size="large"
            //autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label="Password"
            id="upwd"
            {...getFieldProps("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <ColorButton
          fullWidth
          size="large"
          type="submit"
          loading={props.loading}
          variant="contained"
          sx={{ marginTop: "10px" }}
        >
          SignUp
        </ColorButton>
      </Form>
    </FormikProvider>
  );
};

const mapStateToProps = (state) => ({
  userdetails: state.user.userDetails,
  isAuth: state.user.isAuth,
  loading: state.user.loading,
});

export default connect(mapStateToProps)(LoginForm);
