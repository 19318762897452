// @mui material components
//import Container from "@mui/material/Container";
//import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
//import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
//import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";

// About Us page sections

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images

function Services() {
  return (
    <>
      <DefaultNavbar routes={routes} relative />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: 0,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKTypography variant="h4" sx={{ textAlign: "center", mt: 5 }}>
          Services
        </MKTypography>
        <MKTypography sx={{ textAlign: "center" }}>Coming Soon...</MKTypography>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Services;
