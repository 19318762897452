//import { Navigate } from "react-router-dom";
//import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";

// @mui
import { styled } from "@mui/material/styles";
import { Container } from "@mui/material";
// components
import Page from "components/page";
import logo from "../logo.png";
// sections
import LoginForm from "./form";
import MKTypography from "components/MKTypography";

const RootStyle = styled("div")(({ theme }) => ({
  maxWidth: "30%",
  minWidth: 300,
  margin: "auto",
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  //padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    //padding: theme.spacing(7, 5, 0, 7),
  },
}));

const ContentStyle = styled("div")(() => ({
  //maxWidth: 480,
  //margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: "30px 10px 50px 10px",
}));

export default function Login() {
  // const { isAuth } = useSelector((state) => state.user);

  return (
    <Page
      title="Login"
      style={{
        background: "rgba(104,35,106)",
        height: "100vh",
        overflowY: "auto",
        width: "100vw",
        maxWidth: "100vw",
      }}
    >
      <RootStyle>
        <HeaderStyle>
          <Helmet>
            <title>Cochin Gold & Diamonds | SignUp</title>
          </Helmet>
        </HeaderStyle>
        <Container
          style={{
            background: "rgba(255,255,255,0.9)",
            maxWidth: 400,
            marginTop: "20%",
            borderRadius: 10,
          }}
        >
          <ContentStyle>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginLeft: "30%",
                marginRight: "30%",
              }}
            >
              <img src={logo} alt="logo" style={{ width: 70, height: 80, marginRight: "20%" }} />
              <MKTypography variant="h5" sx={{ color: "rgba(104,35,106)", padding: "25px 0px" }}>
                SignUp
              </MKTypography>
            </div>

            <div
              style={{ width: "100%", borderTop: "1px solid rgba(0,0,0,0.3)", margin: "2rem 0" }}
            />
            <LoginForm />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
