import React, { useState } from "react";
import Grid from "@mui/material/Grid";

import PropTypes from "prop-types";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import _ from "lodash";

import "./style.css";
export default function Products({ itemList, total }) {
  const [sort, setSort] = useState("asc");
  return (
    <div style={{ marginBottom: 10 }}>
      <div
        style={{
          padding: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div className="productShowing" style={{ fontSize: "0.8rem", color: "rgba(0,0,0,0.6)" }}>
          Showing <b>{itemList.length}</b> of <b>{total}</b> Products
        </div>
        <div style={{ width: "30%", display: "flex", flexDirection: "row" }}>
          <div style={{ fontSize: "0.8rem", marginRight: 10, opacity: "0.6" }}>Sort By :</div>
          <div style={{ width: "70%", opacity: "0.7" }}>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={sort}
                displayEmpty
                onChange={(e) => {
                  setSort(e.target.value);
                }}
              >
                <MenuItem value={"asc"}>Price: Low to High</MenuItem>
                <MenuItem value={"desc"}>Price: High to Low</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <Grid container spacing={3}>
        {_.orderBy(itemList, [(i) => i.price], sort).map((i) => (
          <Grid item xs={12} sm={6} md={3} key={i.id}>
            <div
              onClick={() => console.log("hello", i.id)}
              style={{
                border: "1px solid rgba(0,0,0,0.2)",
                display: "flex",
                flexDirection: "column",
                padding: 5,
              }}
            >
              <img
                src={i.img}
                alt={i.name}
                width="100%" //give resolution 250x250
                style={{
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              />
              <div style={{ padding: 10 }}>
                {/* <div style={{ color: "rgba(104, 35, 106)", fontSize: "0.9rem" }}>Rs {i.price}</div> */}
                <div style={{ color: "rgba(104, 35, 106, 0.6)", fontSize: "0.7rem" }}>
                  ID: {i.id}
                </div>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

Products.propTypes = {
  itemList: PropTypes.arrayOf(PropTypes.shape),
  total: PropTypes.number,
};
