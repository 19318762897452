// @mui material components
//import Container from "@mui/material/Container";
//import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Slider from "@mui/material/Slider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
//import MKTypography from "components/MKTypography";
//import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import Products from "./products";
// Images
//import bgImage from "./images/inner_header.jpg";
import MKTypography from "components/MKTypography";
import { useEffect, useState } from "react";

import "./style.css";

//images
import img1 from "./images/jewellery/item_1.jpg";
import img2 from "./images/jewellery/item_2.jpg";
import img3 from "./images/jewellery/item_3.jpg";
import img4 from "./images/jewellery/item_4.jpg";

import bgImg from "./images/jewellery/inner_header.jpg";

const cat = [
  { id: 1, name: "Rings" },
  { id: 2, name: "Pendant" },
  { id: 3, name: "Earing" },
  { id: 4, name: "Nosepin" },
  { id: 5, name: "Necklace" },
  { id: 6, name: "Bangle" },
  { id: 7, name: "Bracelet" },
];

const Color = [
  { id: 1, color: "rgba(224, 25, 11)" },
  { id: 2, color: "rgba(10, 25, 11)" },
  { id: 3, color: "rgba(224, 112, 11)" },
  { id: 4, color: "rgba(224, 25, 110)" },
  { id: 5, color: "rgba(22, 250, 11)" },
];

const ListProducts = [
  {
    id: 1,
    name: "product001",
    cat: 1,
    color: 1,
    price: 639,
    img: img1,
  },
  { id: 2, name: "product001", cat: 2, color: 4, price: 1109, img: img2 },
  {
    id: 4,
    name: "product001",
    cat: 1,
    color: 1,
    price: 289,
    img: img3,
  },
  { id: 5, name: "product001", cat: 2, color: 4, price: 789, img: img4 },
  {
    id: 7,
    name: "product001",
    cat: 1,
    color: 1,
    price: 100,
    img: img1,
  },
  { id: 8, name: "product001", cat: 2, color: 4, price: 498, img: img2 },
];

function Collection() {
  const [Copen, setCOpen] = useState(false);
  const [Popen, setPOpen] = useState(false);
  const [CLopen, setCLOpen] = useState(false);

  const [color, setColor] = useState([]);
  const [category, setCategory] = useState([]);
  const [maxPrice, setMaxPrice] = useState();

  const [list, setList] = useState([]);
  const [filter, setFilter] = useState({});

  useEffect(() => {
    setCategory(cat);
    setColor(Color);
    setList([ListProducts]);
    calcMaxPrice();
  }, []);

  useEffect(() => {
    console.log("list", list);
  }, [list]);

  useEffect(() => {
    handleFilter();
  }, [filter]);

  const calcMaxPrice = () => {
    let max = 0;
    ListProducts.forEach((i) => {
      if (i.price > max) max = i.price;
    });
    setMaxPrice(max);
  };

  function valuetext(value) {
    return `${value}`;
  }

  const handleClick = (x) => {
    switch (x) {
      case "cat": {
        setCOpen(!Copen);
        break;
      }
      case "price": {
        setPOpen(!Popen);
        break;
      }
      case "color": {
        setCLOpen(!CLopen);
        break;
      }
      default:
        break;
    }
  };

  const handleSelect = (x, e) => {
    switch (x) {
      case "cat": {
        const { cat = [] } = filter;
        if (!cat.includes(e)) cat.push(e);
        else cat.splice(cat?.indexOf(e), 1);
        setFilter({ ...filter, cat });
        break;
      }
      case "price": {
        setFilter({ ...filter, price: e });
        break;
      }
      case "color": {
        setCLOpen(!CLopen);
        break;
      }
      default:
        break;
    }
  };

  const handleFilter = () => {
    const { cat = [], price: _price = [] } = filter;
    let _data = ListProducts || [];
    if (cat.length > 0) _data = _data.filter((i) => cat.includes(i.cat));

    if (_price && _price.length > 0)
      _data = _data.filter((i) => i.price >= _price[0] && i.price <= _price[1]);

    setList(_data);
  };

  return (
    <>
      <DefaultNavbar routes={routes} relative center />

      <MKTypography
        variant="h2"
        sx={{
          textAlign: "center",
          color: "rgb(255,255,255)",
          padding: "150px 0",
          height: "350px",
          fontFamily: "Helvetica",
          backgroundImage: `url(${bgImg})`,
          //minWidth: { sx: "102vw", sm: "102vw", md: "104.3%" },
        }}
      >
        PRODUCTS
      </MKTypography>

      <Card
        sx={{
          //p: 2,
          //mx: { xs: 2, lg: 3 },
          mt: 0,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          //paddingLeft: "10%",
          //paddingRight: "10%",
          maxWidth: "100vw",
        }}
      >
        <div style={{ display: "flex" }}>
          <div
            className="filterMain"
            //style={}
          >
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <div
                  style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
                >
                  <div style={{ fontSize: "12px", fontWeight: 500 }}>
                    Filter{" "}
                    {Object.keys(filter).length > 0 ? `: (${Object.keys(filter).length})` : ""}
                  </div>
                  <button
                    onClick={() => setFilter({})}
                    style={{
                      color: "rgba(209, 168, 19)",
                      border: "none",
                      backgroundColor: "transparent",
                      fontSize: "0.8rem",
                    }}
                  >
                    Clear All{" "}
                  </button>
                </div>
              }
            >
              <div
                style={{
                  width: "100%",
                  backgroundColor: "rgba(104, 35, 106, 0.5)",
                  height: "1px",
                  margin: "auto",
                  marginTop: 10,
                  marginBottom: 10,
                  maxWidth: "100%",
                }}
              />
              <ListItemButton
                onClick={() => handleClick("cat")}
                style={{ justifyContent: "space-between" }}
              >
                <div style={{ fontSize: "16px" }}>Category</div>
                {Copen ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={Copen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {category.map((i) => (
                    <ListItemButton
                      key={i.id}
                      sx={{ pl: 4 }}
                      onClick={() => handleSelect("cat", i.id)}
                      selected={filter?.cat?.includes(i.id)}
                    >
                      <div style={{ fontSize: "12px" }}>{i.name}</div>
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
              <div
                style={{
                  width: "90%",
                  backgroundColor: "rgba(104, 35, 106, 0.5)",
                  height: "1px",
                  margin: "auto",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              />
              <ListItemButton
                onClick={() => handleClick("price")}
                style={{ justifyContent: "space-between" }}
              >
                <div style={{ fontSize: "16px" }}>Price</div>
                {Popen ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={Popen} timeout="auto" unmountOnExit>
                {/* <List component="div" disablePadding>
                  {price.map((i) => (
                    <ListItemButton key={i.id} sx={{ pl: 4 }}>
                      <div style={{ fontSize: "12px" }}>{i.name}</div>
                    </ListItemButton>
                  ))}
                </List> */}
                <Slider
                  max={maxPrice}
                  min={0}
                  getAriaLabel={() => "Price Range"}
                  value={filter?.price || [0, maxPrice]}
                  onChange={(event, newValue) => handleSelect("price", newValue)}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                />
              </Collapse>
              <div
                style={{
                  width: "90%",
                  backgroundColor: "rgba(104, 35, 106, 0.5)",
                  height: "1px",
                  margin: "auto",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              />
              <ListItemButton
                onClick={() => handleClick("color")}
                style={{ justifyContent: "space-between" }}
              >
                <div style={{ fontSize: "16px" }}>Colour</div>
                {CLopen ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={CLopen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {color.map((i) => (
                    <ListItemButton key={i.id} sx={{ pl: 4 }}>
                      <div
                        style={{
                          height: "30px",
                          width: "32px",
                          backgroundColor: i.color,
                          borderRadius: "50%",
                        }}
                      />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </List>
          </div>
          <div className="productMain">
            <Products itemList={list} total={ListProducts.length} />
          </div>
        </div>
      </Card>
      <MKBox pt={6} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Collection;
