/* eslint-disable react/prop-types */
import * as Yup from "yup";
import { useState } from "react";
import { Link as RouterLink /* , useNavigate */ } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
// material
import { Link, Stack, TextField, IconButton, InputAdornment } from "@mui/material";
import { connect } from "react-redux";
//import "../../../redux/user/user.css";
// component
import Iconify from "components/Iconify";

// ----------------------------------------------------------------------

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { purple } from "@mui/material/colors";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  "&:hover": {
    backgroundColor: purple[700],
  },
}));

const LoginForm = (props) => {
  //const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Email must be a valid email address").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (values, { setSubmitting }) => {
      //
      const userdetails = {
        useremail: document.getElementById("uemail").value,
        password: document.getElementById("upwd").value,
      };
      props.dispatch({ type: "LOGIN", payload: userdetails });
      setTimeout(() => {
        setSubmitting(false);
      }, 2000);
      // if(props.isAuth) navigate('/', { replace: true });
    },
  });

  const { errors, touched, /* values, isSubmitting, */ handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            size="large"
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            id="uemail"
            {...getFieldProps("email")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            size="large"
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label="Password"
            id="upwd"
            {...getFieldProps("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <ColorButton
          fullWidth
          size="large"
          type="submit"
          loading={props.loading}
          variant="contained"
          sx={{ marginTop: "10px" }}
        >
          Login
        </ColorButton>

        <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 2 }}>
          <Link component={RouterLink} variant="subtitle2" to="/createAccount" underline="hover">
            {"Don't have an account? SignUp!"}
          </Link>
        </Stack>
      </Form>
    </FormikProvider>
  );
};

const mapStateToProps = (state) => ({
  userdetails: state.user.userDetails,
  isAuth: state.user.isAuth,
  loading: state.user.loading,
});

export default connect(mapStateToProps)(LoginForm);
