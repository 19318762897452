import axios from "axios";
import Client from "../../services/api";

let resp;

axios.defaults.withCredentials = true;

export async function loginUser(credentials) {
  try {
    resp = await Client.post("auth/login", credentials);
    console.log("resp.data", resp.data);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
}

export async function verifyLoggedUser() {
  try {
    resp = await Client.get("auth/current_account");
    console.log("resp.data current", resp);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
}

export async function logoutUser() {
  try {
    resp = await Client.post("auth/logout");
    console.log("resp.data logout", resp.data);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
}
