import React from "react";
import { ToastContainer } from "react-toastify";
//import { makeStyles } from "@material-ui/core/styles";

function Toast() {
  /* const useStyles = makeStyles({
    toastBody: ,
    toastClass: ,
  });

  const classes = useStyles(); */

  return (
    <ToastContainer
      closeButton={false}
      position="bottom-right"
      hideProgressBar={false}
      autoClose={5000}
      closeOnClick
      draggable={false}
      rtl={false}
      bodyClassName={{
        color: "#ffffff",
      }}
      toastClassName={{
        background: "rgba(0,0,0,0.9)",
        minHeight: 50,
      }}
      progressStyle={{ height: 2 }}
    />
  );
}

export default Toast;
