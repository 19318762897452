/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";
import Create from "./pages/login/create";
import Toast from "./examples/toast";

// Material Kit 2 React routes
import routes from "./routes";
import Login from "./pages/login";
import Verify from "pages/login/verify";

function App(props) {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Toast />
      <Routes>
        {getRoutes(routes)}
        <Route path="/login" element={<Login />} />
        <Route path="/createAccount" element={<Create />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/*" element={<Navigate to={`/home`} replace />} />
      </Routes>
    </ThemeProvider>
  );
}

const mapStateToProps = (state) => ({
  isAuth: state.user.isAuth,
  role: state.user.role,
});

export default connect(mapStateToProps)(App);
